.loader {
    border: 5px solid #f3f3f3;
    /* Light grey */
    border-top: 5px solid #3498db;
    /* Blue */
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.bg-red {
    background-color: #CA0505;
}

.bg-yellow {
    background-color: #FFAE1F;
}

.bg-lavender {
    background-color: #EEF0FF;
}

.content-body {
    background-color: #ffffff;
}

.session-expiry {
    border-left: 1px solid grey;
    padding-left: 0.75rem;
    width: 35%;
}

.session-expiry p,
.session-expiry h6 {
    font-size: 10px;
    color: #030E52;
    text-align: center;
}

.header-right .header-border button {
    background-color: transparent !important;
    border: 1px solid #ffffff !important;
    font-size: 1rem !important;
    padding: 6px 15px !important;
}

.header-profile2 {
    padding-right: 30px;
}

.nav-control .is-active .nav-header {
    left: 4rem;
}

.deznav .metismenu {
    padding-top: 30px;
    padding-bottom: 50px;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .astroLogo img {
    width: 100%;
}

.text-left {
    text-align: left;
}

.help-desk {
    position: absolute;
    width: 100%;
    bottom: 0;
    background-color: #030E52;
}

.help-desk img {
    width: 30px;
}

.help-desk a {
    font-size: 1rem;
    color: #ffffff;
    padding-left: 10px;
}

@media only screen and (max-width: 63.9375rem) {
    [data-sidebar-style="mini"] .astroLogo {
        margin-top: 3.8rem;
    }

    [data-sidebar-style="mini"] .astroLogo img {
        width: 100%;
    }
}

@media only screen and (max-width: 47.9375rem) {
    .header-profile2 {
        padding-right: 0;
    }

    .header-right .header-media {
        width: 2.5rem;
        height: 2.5rem;
    }

    .header-right .notification_dropdown .nav-link svg {
        width: 2rem;
        height: 2rem;
    }

    .page-titles {
        padding: 0 0.75rem 0.5rem;
    }

    .page-titles .breadcrumb {
        width: 65%;
    }

    .page-titles .breadcrumb li a {
        font-size: 10px;
    }

    .pagination .page-item .page-link {
        width: 2rem;
        height: 2rem;
    }

    /* .swal2-icon{
        width: 3rem;
        height: 3rem;
    }
    .swal2-icon .swal2-icon-content{
        font-size: 1.75rem;
    } */
    .swal2-title {
        font-size: 1rem !important;
    }

    .swal2-styled.swal2-cancel {
        font-size: 0.78rem;
    }

    .swal2-html-container {
        font-size: 0.75rem !important;
    }

    .help-desk {
        bottom: 40px;
    }

    .deznav .metismenu {
        padding-bottom: 100px;
    }
}

/* Network */
.low-network,
.offline {
    width: 450px;
    position: fixed;
    background-color: #fff;
    z-index: 1000;
    right: 3%;
    top: 15%;
    padding: 10px 15px;
    border: 1px solid lightgray;
    border-radius: 5px;
    /* color: orange; */
    font-size: 16px;
    font-weight: 600;
}

.low-network .icon {
    color: orange;
    font-size: 40px;
}

.offline .icon {
    color: red;
    font-size: 40px;
}

@media (max-width:550px) {

    .low-network,
    .offline {
        width: 400px;
        font-size: 12px;
        top: 10%;
        right: 8%;
    }

    .low-network .icon,
    .offline .icon {
        font-size: 30px;
    }
}

@media (max-width:400px) {

    .low-network,
    .offline {
        width: 325px;
        right: 5%;
    }
}

/* dashboard */
.offerMain,
.margin-3 {
    margin: 3rem;
}

.offer-card {
    width: 32% !important;
    position: relative;
}

.offer-card img {
    width: 100%;
    height: 100%;
}

.offer-card h5 {
    color: #ffffff;
    font-weight: 600;
    position: absolute;
    bottom: 5px;
    left: 10px;
}

.widget-card {
    display: flex;
    justify-content: space-between;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    margin-bottom: 30px;
}

.widget-card p {
    font-size: 20px;
    margin-bottom: 0px;
    color: #030E52;
}

.widget-card .click-icon {
    font-size: 24px;
    color: #030E52;
}

.widget-card .file-icon {
    font-size: 34px;
    color: #030E52;
}

.status-card {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    border-radius: 5px;
    padding: 10px 20px;
}

.status-card table {
    width: 100% !important;
    text-align: center;

}

.status-card table th,
.status-card .title,
.status-card .description {
    color: #6B6B6B;
    font-size: 18px;
    text-decoration: underline;
    padding: 10px 0px;
    text-align: center;
}

.status-card .title {
    color: #030E52;
    font-weight: 600;
}

.status-card .description {
    text-decoration: none;
}

.status-card table td {
    color: #6B6B6B;
    font-size: 18px;
    padding: 10px 0px;
}

@media only screen and (max-width: 63.9375rem) {
    .widget-card-main {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .widget-card {
        width: 48% !important;
        margin-bottom: 20px;
    }

    .offer-card {
        width: 48% !important;
    }


}

@media only screen and (max-width: 47.9375rem) {
    .offerMain {
        margin: 0;
    }

    .margin-3 {
        margin: 1rem;
    }

    .widget-card-main {
        width: 100%;
        gap: 1rem;
        flex-direction: column !important;
    }

    .widgetMain .col-sm-12 {
        width: 100% !important;
    }

    .widget-card {
        width: 100% !important;
        margin-bottom: 0;
    }

    .widget-card p {
        font-size: 14px;
    }

    .offer-card {
        width: 100% !important;
        height: 160px;
    }

    .status-card {
        display: flex;
        justify-content: space-between;
    }

    .status-card .col-sm-12 {
        flex-direction: column !important;
        justify-content: space-between;
    }

    .status-card .gap-5 {
        gap: 1rem !important;
    }

    .status-card .title,
    .status-card .description {
        font-size: 12px;
        margin-bottom: 0px;
    }
}

@media only screen and (max-width: 1400px) and (min-width: 1200px) {
    .status-card .gap-5 {
        gap: 2rem !important;
    }
}

/* ToggleSwitch.css */
.toggle-switch {
    position: relative;
    display: inline-block;
    width: 100px;
    height: 40px;
}

.toggle-switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.switch {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ffffff;
    border-radius: 34px;
    transition: background-color 0.4s;
    border: 1px solid #030E52;
}

.switch::before {
    position: absolute;
    content: '';
    height: 28px;
    width: 28px;
    left: 8px;
    bottom: 6px;
    background-color: #030E52;
    border-radius: 50%;
    transition: transform 0.4s;
}

input:checked+.switch {
    background-color: #097500;
    border: 1px solid #097500;

}

input:checked+.switch::before {
    transform: translateX(56px);
    background-color: #ffffff;
}

.switch-text {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 42%;
    color: #030E52;
    font-size: 14px;
}

input:checked+.switch .switch-text {
    left: calc(10% - 0px);
    color: #ffffff;
}

.online-switch {
    width: 180px;
    height: 40px;
    background-color: #898989 !important;
    color: #ffffff;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin-left: 100px; */
    cursor: pointer;
    font-size: 12px;
    border: none;
}

.status-card .active {
    background-color: #097500 !important;
}

/* Next Online */
.next-online-select {
    display: flex;
    justify-content: space-between;
}

.next-online-select input {
    width: 100%;
    height: 40px;
    padding: 5px 10px;
    border-radius: 5px;
}

.next-online-select .react-datepicker__calendar-icon {
    right: 5px;
    top: 5px;
}

@media only screen and (max-width: 63.9375rem) {
    .online-switch {
        width: 130px;
        height: 50px;
    }
}

@media only screen and (max-width: 47.9375rem) {
    .status-card-main {
        width: 100% !important;
    }

    .online-switch {
        width: 100px;
        height: 40px;
        font-size: 10px;
        line-height: normal;
    }

    .toggle-switch {
        width: 80px;
        height: 30px;
    }

    .switch::before {
        width: 16px;
        height: 16px;
    }

    .switch-text {
        font-size: 10px;
    }

    input:checked+.switch::before {
        transform: translate(45px);
    }

    input:checked+.switch .switch-text {
        left: calc(15% - 0px);
    }

    .next-online-modal .modal-dialog {
        justify-content: center;
    }

    .next-online-modal .modal-content {
        width: 80%;
    }

    .next-online-select {
        flex-direction: column;
        gap: 10px;
    }

    .next-online-select h5 {
        font-size: 14px;
    }

    .next-online-select .react-datepicker-wrapper {
        width: 100% !important;
    }

    .next-online-select input {
        height: 35px;
        font-size: 12px;
    }

    .next-online-modal .modal-footer button {
        font-size: 12px;
        padding: 0.5rem !important;
    }

    .next-online-modal .modal-title {
        font-size: 16px;
    }

    .next-online-modal .modal-body {
        padding: 1.25rem;
    }
}

@media only screen and (max-width: 25.9375rem) {
    .online-switch {
        width: 80px;
        height: 35px;
        font-size: 8px;
    }

    /* .toggle-switch {
        width: 60px;
        height: 25px;
    } */
}

@media only screen and (max-width: 1400px) and (min-width: 1300px) {
    .switch-text {
        font-size: 13px;
    }

    .switch::before {
        width: 20px;
        height: 20px;
        bottom: 9px;
    }

    input:checked+.switch::before {
        transform: translateX(50px);
    }
}

@media only screen and (max-width: 1300px) and (min-width: 1200px) {
    .switch-text {
        font-size: 12px;
    }

    .switch::before {
        width: 20px;
        height: 20px;
        bottom: 9px;
    }

    input:checked+.switch::before {
        transform: translateX(45px);
    }
}

/* Training Videos */
.training-videos .training-videos-card {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    padding: 20px 10px;
    border-radius: 5px;
    width: 32% !important;
}

.training-videos .training-videos-card video {
    width: 100%;
    height: 400px;
}

.training-videos .training-videos-card h5 {
    color: #030E52;
    font-weight: 700;
    margin-top: 10px;
}

@media only screen and (max-width: 67.9375rem) {
    .training-videos .training-videos-card {
        width: 49% !important;
    }
}

@media only screen and (max-width: 47.9375rem) {
    .training-videos .training-videos-card {
        width: 100% !important;
    }
}

/* Login */
.login-page {
    background-image: url(../images/dashboard/login-background.png);
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-another p {
    margin-bottom: 0px !important;
}

.login-another .account {
    text-decoration: underline;
    cursor: pointer;
}

.login-screen {
    background: rgba(255, 255, 255, 0.2) !important;
    backdrop-filter: blur(6px);
    color: #ffffff !important;
    padding: 15px !important;
    padding: 40px !important;
    border-radius: 10px;
}

.login-screen input {
    width: 90%;
    height: 50px;
    margin-left: 5%;
    font-size: 18px;
    border-radius: 5px;
    padding-left: 10px;
}

.login-screen .react-tel-input {
    margin-left: 5% !important;
}

.login-screen .react-tel-input .form-control {
    width: 90% !important;
    height: 50px !important;
    font-size: 18px !important;
}

.login-screen p {
    font-size: 18px !important;
    display: flex;
    justify-content: center;
    width: 90%;
    margin-left: 5%;

}

.note-message {
    background-color: #ffffff;
    color: #CA0505;
    padding: 5px;
    border-radius: 5px;
}

.login-screen .note-message p {
    font-size: 13px !important;
    margin-bottom: 0;
}

.otp-button {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    font-size: 18px !important;
    font-weight: 700 !important;
    width: 150px !important;
    height: 45px !important;
    box-shadow: none !important;
    border: none !important;
    border-radius: 5px;
}

.login-screen .react-tel-input .country-list .country-name {
    color: #000 !important;
}

.Otp-form p {
    font-size: 18px;
}

.otp-input-fields {
    margin: auto;
    /* background-color: white; */
    max-width: 400px;
    width: auto;
    display: flex;
    justify-content: center;
    padding: 20px;
}

.otp-input-fields input {
    height: 50px;
    width: 50px;
    border: 1px solid #ffffff;
    border-radius: 5px;
    text-align: center;
    outline: none;
    font-size: 16px;
    color: #000;
    padding-left: 0px;
    /* Firefox */
}

.otp-input-fields input::-webkit-outer-spin-button,
.otp-input-fields input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.otp-input-fields input[type=number] {
    -moz-appearance: textfield;
}

.otp-input-fields input:focus {
    border: 2px solid;
    border-color: #ffffff;
    font-size: 20px;
}

.resend-otp a {
    text-decoration: underline !important;
    color: #3498db !important;
    cursor: pointer;
}

.detail-form .half-form {
    width: 32%;
}

.detail-form .half-form input,
.detail-form .half-form select {
    width: 100% !important;
    height: 40px;
    border-radius: 5px;
}

.detail-form .birth-time select {
    width: 30% !important;
}

.confirm-modal .modal-content {
    background-color: #FFF9E6 !important;
    padding: 15px !important;
}

.confirm-modal .thank-text {
    font-size: 20px;
}

.edit-form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.edit-form .half-form input {
    padding: 5px 15px;
    border: 1px solid #030E52;
}

.emailInput {
    color: #000 !important;
}

@media (max-width:950px) {
    .detail-form .half-form {
        width: 48%;
    }
}

@media (max-width:550px) {
    .login-screen {
        padding: 20px !important;
    }

    .login-modal .login-screen .react-tel-input .form-control {
        width: 100% !important;
    }

    .login-modal .login-screen p {
        font-size: 18px !important;
    }

    .otp-button {
        font-size: 14px !important;
        height: 35px !important;
        width: 100px !important;
    }

    .Otp-form p {
        font-size: 16px;
    }

    .otp-input-fields {
        gap: 10px;
    }

    .otp-input-fields input {
        width: 45px !important;
        height: 45px !important;
    }

    .detail-form .half-form {
        width: 100%;
    }

    .login-screen p {
        font-size: 12px !important;
        width: 100%;
        margin-left: 0;
    }

    .login-screen .login-heading {
        font-size: 14px !important;
    }

    .login-screen .react-tel-input {
        margin-left: 0 !important;
    }

    .login-screen .react-tel-input .form-control,
    .login-screen input {
        width: 100% !important;
        height: 35px !important;
        font-size: 14px !important;
    }

    .login-screen input {
        margin-left: 0;
        border: none;
    }

    /* Popup */
    .swal2-popup {
        width: 20rem;
    }

    .swal2-title {
        padding: 0;
        font-size: 1.25rem;
    }

    .swal2-styled.swal2-confirm {
        padding: 0.5rem 0.75rem;
        font-size: 0.75rem;
    }
}

@media (max-width:400px) {
    .otp-input-fields {
        gap: 10px;
    }

    .otp-input-fields input {
        width: 40px !important;
        height: 40px !important;
    }

    .Otp-list {
        padding-left: 0;
        padding-right: 0;
    }

    .Otp-form {
        max-width: 100%;
    }

    .Otp-form p {
        font-size: 14px;
    }
}

@media (max-width:350px) {
    .otp-input-fields input {
        width: 35px;
        height: 35px;
    }
}


/* Order History */
/* .call-list-main{
    border-right: 1px solid rgba(0, 0, 0, 20%);
} */
.call-list-title {
    padding: 20px 5px;
}

.call-list .table-100 {
    width: 100% !important;
}

.call-list .table-60 {
    width: 62% !important;
}

[data-sidebar-style="full"][data-layout="vertical"] .call-list .table-60 {
    width: 65%;
}

.call-list table tr {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.call-list table th,
.call-list table td {
    padding: 15px;
    font-size: 18px;
    text-align: center;
}

[data-sidebar-style="full"][data-layout="vertical"] .call-list .table-60 th,
[data-sidebar-style="full"][data-layout="vertical"] .call-list .table-60 td {
    font-size: 16px;
    padding: 10px;
}

.call-list table .view {
    color: #025BFD;
    text-decoration: underline;
    cursor: pointer;
}

.hide-list {
    display: none;
}


.call-canvas {
    width: 500px !important;
    margin-top: 4.5rem;
    background-color: #ffffff;
}

.call-canvas .offcanvas-body {
    display: flex;
    align-items: center;
}

.call-canvas .preview-card {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    border-radius: 5px;
    padding: 20px;
}

.call-canvas .preview-card .preview-data {
    font-size: 16px;
    margin-bottom: 5px !important;
    display: flex;
    gap: 10px;
}

.call-canvas .preview-card .preview-data span {
    font-weight: 600;
}

.call-canvas .call-audio audio {
    width: 100% !important;
}

.call-canvas .call-profile-img {
    display: flex;
    justify-content: center;
    margin-top: 4rem;
    width: 100px;
    height: 100px;
}

.call-canvas .call-profile-img img {
    border-radius: 50%;
}

.view-chat-btn button {
    background-color: transparent;
    border: none;
    text-decoration: underline;
    color: #030E52;
    font-weight: 600;
}

.chat-history-header {
    background-color: #FFE799;
}

.chat-history-header h4 {
    font-weight: 600;
}

.chat-history-header .back-icon .icon {
    font-size: 24px;
    font-weight: 600;
    color: #030E52;
    cursor: pointer;
}

.chat-history-header button {
    display: none;
}

.chat-history-message {
    padding-bottom: 0 !important;
    padding-top: 40px !important;
}

.user-review {
    background-color: #FFF7E1;
}

.user-review .profileStar {
    justify-content: flex-start;
}

@media only screen and (max-width: 63.9375rem) {

    .call-list table th,
    .call-list table td {
        padding: 10px;
        font-size: 14px;
    }
}

@media only screen and (max-width: 47.9375rem) {
    .call-list {
        overflow-x: auto;
    }

    .call-list table th,
    .call-list table td {
        padding: 10px;
        font-size: 12px;
    }

    .call-list .table-100,
    .call-list .table-60 {
        width: 150% !important;
        box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
        border: 1px solid rgba(0, 0, 0, 0.05);
        border-radius: 5px;
    }

    .mobile-hide {
        display: none;
    }

    /* .hide-list{
        display: inline;
    } */
    .call-canvas .preview-card .preview-data {
        font-size: 12px;
    }

    .call-canvas {
        top: auto !important;
        right: auto !important;
        bottom: 0;
    }

    .call-canvas .offcanvas-header {
        padding: 15px 0px 0px 15px;
    }

    .call-canvas .offcanvas-body {
        display: inline;
        padding: 0px;
    }

    .call-canvas .call-audio audio {
        height: 35px;
    }
}

/* Profile */
.profile-head {
    position: relative;
}

.profile .profile-head .profile-photo {
    position: relative;
}

.profile .profile-head .profile-photo:hover .profile-photo-update {
    opacity: 1;
}

.profile .profile-head .profile-photo-update {
    opacity: 0;
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: flex-end;
    position: absolute;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.profile .profile-head .profile-photo-update .profile-icon .icon {
    font-size: 24px;
    margin-bottom: 20px;
    color: #ffffff;
    cursor: pointer;
}

.profile-info .profile-details h4 {
    color: #030E52 !important;
    font-weight: 700;
    font-size: 18px;
}

.followers-card {
    position: absolute;
    right: 0;
    bottom: 1rem;
}

.followers-card .card-body {
    background-color: #FFF9E6;
    padding: 0.5rem !important;
}

.followers-card .card-body h3 {
    color: #025BFD;
    font-weight: 700;
}

.followers-card .card-body span {
    color: #323232;
    font-weight: 700;
}

.profile-update .profile-icon {
    background-color: #ffffff;
    border-radius: 5px;
    padding: 5px;
}

.profile-update .icon {
    font-size: 24px;
    filter: brightness(0) saturate(100%) invert(6%) sepia(84%) saturate(7071%) hue-rotate(217deg) brightness(71%) contrast(116%);
    cursor: pointer;
}

.profile-about-me h4,
.profile-personal-info h4,
.profile-contact-info h4,
.profile-skills h4,
.profile-lang h4 {
    font-size: 20px;
    font-weight: 600;
    color: #030E52 !important;
    text-decoration: underline;
}

.profile-about-me p {
    font-size: 16px;
}

.profile-personal-info h5,
.profile-contact-info h5,
.profile-personal-info span,
.profile-contact-info span {
    font-size: 16px;
}

.profile-skills a {
    font-size: 16px !important;
    color: #2c2c2c !important;
    background-color: #FFF9E6 !important;
    padding: 10px !important;
    margin-right: 10px !important;
}

.profile-skills a:hover {
    border: 1px solid #FFAE1F !important;
}

.profile-lang a {
    font-size: 16px !important;
}

@media only screen and (max-width: 63.9375rem) {
    .photo-content .cover-photo {
        min-height: 10.625rem;
    }

    .profile .profile-photo {
        max-width: 7rem;
    }

    .followers-card .card-body {
        width: 140px;
    }

    .followers-card .card-body h3 {
        font-size: 16px;
    }

    .followers-card .card-body span {
        font-size: 12px;
    }

    .profile-info .profile-details h4,
    .profile-about-me p {
        font-size: 14px;
    }

    .profile-info p {
        font-size: 12px;
    }

    .profile-about-me h4,
    .profile-personal-info h4,
    .profile-contact-info h4,
    .profile-skills h4,
    .profile-lang h4 {
        font-size: 16px;
    }

    .profile-about-me h5,
    .profile-personal-info h5,
    .profile-contact-info h5,
    .profile-skills h5,
    .profile-lang h5 {
        font-size: 14px;
    }

    .profile-personal-info span,
    .profile-contact-info span {
        font-size: 14px;
        padding: 0px 6px;
    }

    .profile-skills a {
        font-size: 10px !important;
        padding: 6px !important;
    }

    .profile-lang a {
        font-size: 14px !important;
    }
}

@media only screen and (max-width: 47.9375rem) {
    .photo-content .cover-photo {
        min-height: 6.625rem;
    }

    .profile .profile-photo {
        max-width: 5rem;
        margin-top: -4.5rem;
        margin-bottom: 0;
    }

    .followers-card {
        position: unset;
        display: flex;
        justify-content: center;
    }

    .followers-card .card-body {
        width: 120px;
    }

    .followers-card .card-body h3 {
        font-size: 12px;
        margin-bottom: 0px !important;
    }

    .followers-card .card-body span {
        font-size: 8px;
    }

    .profile-info .profile-details h4,
    .profile-about-me p {
        font-size: 12px;
    }

    .profile-info p {
        font-size: 12px;
    }

    .profile-about-me h4,
    .profile-personal-info h4,
    .profile-contact-info h4,
    .profile-skills h4,
    .profile-lang h4 {
        font-size: 14px;
    }

    .profile-about-me h5,
    .profile-personal-info h5,
    .profile-contact-info h5,
    .profile-skills h5,
    .profile-lang h5 {
        font-size: 12px;
    }

    .profile-personal-info span,
    .profile-contact-info span {
        font-size: 12px;
        padding: 0px 6px;
    }

    .profile-skills a {
        font-size: 10px !important;
        padding: 6px !important;
    }

    .profile-lang a {
        font-size: 12px !important;
    }

    .profile-tab .col-sm-6 {
        width: 100% !important;
    }

    .profile-update .icon {
        font-size: 20px;
    }

    .profile-info {
        padding: 0;
    }
}

/* Bank details */

.details__wrapper {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    border-radius: 5px;
    padding: 20px;
}

.form__item {
    margin-bottom: 15px;
    width: 100% !important;
}

.details__wrapper .half-form {
    width: 49% !important;
}

.details__wrapper input,
.details__wrapper select,
.details__wrapper textarea,
.details__wrapper .search-wrapper,
.details__wrapper .react-datepicker-wrapper {
    display: block;
    width: 100% !important;
    border-radius: 5px;
    height: 40px !important;
    background-color: #F5F5F5;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    border: none !important;
}

.details__wrapper .css-13cymwt-control {
    background-color: #F5F5F5;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    height: 40px !important;
}

.details__wrapper .css-qbdosj-Input {
    height: 30px !important;
}

.details__wrapper .css-b62m3t-container input {
    box-shadow: none !important;
}

.css-tj5bde-Svg {
    color: #000 !important;
}

.details__wrapper .react-datepicker__calendar-icon {
    right: 5px;
    top: 0;
    width: 1.5rem;
    height: 1.5rem;
    filter: brightness(0) saturate(100%) invert(10%) sepia(34%) saturate(5849%) hue-rotate(228deg) brightness(85%) contrast(114%);
}

.details__wrapper .search-wrapper {
    height: 100% !important;
    display: flex;
    overflow-x: auto;
    padding: 0px !important;
}

.details__wrapper .search-wrapper input {
    width: 100% !important;
    height: 40px !important;
    margin-top: 0px !important;
    padding: 0px 10px;
}

.details__wrapper input,
.details__wrapper select {
    border: 1px solid grey;
}


.details__wrapper .search-wrapper::-webkit-scrollbar {
    display: none;
}

.details__wrapper textarea {
    background-color: #F5F5F5;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    border: none !important;
    height: 100% !important;
}

.input__error input {
    border-color: red;
}

.input__error select {
    border-color: red;
}

.input__error textarea {
    border-color: red;
}

.error__feedback {
    color: red;
}



.details__wrapper .register-btn {
    background-color: transparent !important;
    color: #030E52 !important;
    border: none !important;
    box-shadow: none !important;
    font-size: 18px;
    font-weight: 700;
}

.details__wrapper .verify-btn,
.details__wrapper .verify-btn {
    width: 100px;
    height: 40px;
    font-size: 18px;
    border: none !important;
}

.details__wrapper .chip,
.details__wrapper .highlightOption,
.details__wrapper .option:hover {
    background-color: #030E52 !important;
}


.upload-img {
    border-radius: 5px;
}

.upload-img .astro-file-upload {
    display: flex;
    height: 60px !important;
    min-height: 60px !important;
    padding: 10px !important;
    position: relative;
}

.upload-img .astro-file-upload .fallback {
    z-index: 1000;
}

.upload-img .astro-file-upload input {
    opacity: 0;
    z-index: 1000;
}

.upload-img .astro-file-upload .file-icon,
.bank-file-upload button .file-icon {
    width: 30px;
    height: 30px;
    filter: brightness(0) saturate(100%) invert(9%) sepia(35%) saturate(6249%) hue-rotate(230deg) brightness(87%) contrast(113%);
}

.upload-img .astro-file-upload .upload-img-text {
    position: absolute;
    left: 50px;
}

.upload-img .astro-file-upload .upload-img-text p {
    margin-bottom: 0px !important;
    font-size: 12px;
    text-align: left;
}

.upload-img .astro-file-upload .upload-img-text .sub-note {
    font-size: 10px;
}

.upload-img .astro-file-upload .upload-img-text .file-name {
    font-size: 16px;
}

.bank-file-upload .file-upload {
    position: relative;
    width: 200px;
    height: 40px;
}

.bank-file-upload input {
    border-radius: 5px 0px 0px 5px !important;
}

.bank-file-upload .file-upload input {
    opacity: 0;
    z-index: 1000;
    cursor: pointer;
}

.bank-file-upload .file-upload .upload-btn {
    position: absolute;
    top: -2px;
    left: 0;
    width: 100%;
    height: 42px;
    background-color: #030E52;
    color: #ffffff;
    border-radius: 0px 5px 5px 0px;
}

.bank-file-upload .file-upload .upload-btn .file-icon {
    filter: brightness(1) invert(0);
    width: 25px;
    height: 25px;
}

/* Bank detail */

.details__wrapper .edit-button {
    background-color: #FFE1A6;
    color: #030E52;
    padding: 4px 10px;
    border-radius: 5px;
    font-size: 16px;
    border: none;
}

@media only screen and (max-width: 63.9375rem) {
    .details__wrapper .col-lg-6 {
        width: 100% !important;
    }

    .form__item2 .otp-button {
        width: 100px !important;
        height: 35px !important;
        font-size: 14px !important;
    }
}

@media only screen and (max-width: 47.9375rem) {
    .details__wrapper {
        padding: 0;
    }

    .details__wrapper .bank-detail {
        flex-direction: column;
    }

    .details__wrapper label {
        font-size: 12px;
    }

    .details__wrapper input,
    .details__wrapper select,
    .details__wrapper textarea,
    .details__wrapper .search-wrapper,
    .details__wrapper .react-datepicker-wrapper {
        height: 35px !important;
        font-size: 12px;
    }

    .bank-file-upload .file-upload .upload-btn {
        top: -1px;
        height: 37px;
        font-size: 12px;
    }

    .bank-file-upload .file-upload .upload-btn .file-icon {
        width: 20px;
        height: 20px;
    }

    .details__wrapper .col-sm-12 {
        width: 100%;
    }

    .details__wrapper .verify-btn {
        width: 70px;
        height: 30px;
        font-size: 14px;
    }

    .details__wrapper .edit-button {
        font-size: 14px;
        padding: 4px 6px;
    }

    .form__item2 .otp-button {
        width: 80px !important;
        height: 30px !important;
        font-size: 12px !important;
    }
}

/* Waiting List */
.waiting-list .request-select {
    font-size: 14px;
    padding: 8px;
    border: none;
    border-radius: 5px;

}

.waiting-list .nav-tabs {
    display: flex;
    justify-content: center;
    gap: 20px;
    border-bottom: none !important;
    margin: 30px 0px !important;
}

.waiting-list .nav-tabs .nav-item button {
    background-color: #D9D9D9;
    color: #000;
    font-weight: 600;
    padding: 10px 40px;
    font-size: 18px;
    border: none;
    border-radius: 30px;
}


.waiting-list .nav-tabs .nav-item .active,
.waiting-list .nav-tabs .nav-item button:hover {
    background-color: #FFAE1F;
    color: #ffffff;
}

.waiting-details-btn {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin: 20px 0px;
}

.waiting-details-btn button {
    background-color: transparent;
    color: #FFAE1F;
    font-weight: 600;
    padding: 10px 30px;
    font-size: 16px;
    border: 1px solid #FFAE1F;
    border-radius: 30px;
}

.waiting-details-btn .waiting-details-type {
    background-color: #030E52;
    color: #ffffff;
    border: 1px solid #030E52;

}

.waiting-details-btn button:hover {
    background-color: #FFAE1F;
    color: #ffffff;
}

.waiting-user-detail {
    width: 48% !important;
    display: flex;
    flex-wrap: wrap;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    border-radius: 5px;
    padding: 30px;

}

.birth-chart {
    padding: 20px;
}

.waiting-user-detail h4 {
    text-decoration: underline;
    font-size: 20px;
}

.waiting-user-detail img {
    width: 100%;
    border-radius: 50%;
}

.call-connect {
    flex-direction: column;
    background-color: #FFF9E6;
    position: fixed;
    right: 5%;
    width: 30% !important;
}

.call-connect-none {
    display: none;
}

.call-connect h5,
.call-connect h3 {
    text-align: center;
    line-height: normal;
}

.call-connect h3 {
    color: #097500;
}

.call-connect .call-profile {
    width: 100px;
    height: 100px;
}

.call-notes {
    position: fixed;
    bottom: 10%;
    right: 5%;
    width: 360px;
    background-color: #FFF9E6;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.call-notes h4 {
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    color: #030E52;
}

.call-notes textarea {
    border: none;
    background-color: #FFF9E6;
}

.notes-btn {
    display: flex;
    justify-content: flex-end;
}

.notes-btn .notes-save-btn,
.call-notes .notes-save-btn {
    padding: 6px 12px;
    background-color: #097500;
    color: #ffffff;
    border: none;
    border-radius: 5px;
}

.notes-btn {
    position: relative;
}

.notes-btn .notes-save-btn {
    padding: 10px 20px;
}

.notes-btn .bg-red {
    background-color: #CA0505 !important;
}

.call-connect .time-count {
    font-size: 16px;
    color: #CA0505;
}

.waiting-user-detail .reload-aleart p {
    margin-bottom: 5px;
}

.waiting-user-detail .nav {
    display: flex;
    gap: 10px;
    border: none;
}

.waiting-user-detail .nav-link {
    border: none;
    background-color: transparent;
    color: #030E52;
    font-weight: 600;
    text-decoration: underline;
    border-bottom: none;
}

.waiting-user-detail .nav-link.active {
    background-color: #030E52;
    color: #ffffff;
    border-radius: 5px;
    text-decoration: none;
}

.waiting-user-detail .tab-content {
    width: 100%;
}

@media only screen and (max-width: 63.9375rem) {
    .waiting-list .nav-tabs .nav-item button {
        font-size: 16px;
        padding: 10px 30px;
    }

    .waiting-details-btn {
        margin: 10px 0px;
    }

    .waiting-details-btn button {
        height: 40px;
        font-size: 12px;
        padding: 8px 16px;
    }

    .waiting-user-detail h4 {
        font-size: 16px;
    }

    .call-connect .call-profile {
        width: 60px;
        height: 60px;
    }

    .call-connect .call-wave {
        width: 20px;
    }

    .call-connect h3 {
        font-size: 12px;
    }

    .call-connect h5 {
        font-size: 12px;
    }

    .notes-btn .notes-save-btn {
        padding: 5px 10px;
    }

    .call-notes {
        width: 250px;
    }

    .call-notes textarea {
        width: 200px;
    }

    .call-notes h4 {
        font-size: 16px;
    }

    .call-notes .notes-save-btn {
        font-size: 12px;
        padding: 4px 8px;
    }

    .waiting-user-detail .tab-content {
        box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    }
}

@media only screen and (max-width: 47.9375rem) {
    .waiting-list .nav-tabs .nav-item button {
        font-size: 14px;
        padding: 6px 25px;
    }

    .waiting-user-detail h4 {
        font-size: 14px;
    }

    .waiting-user-detail h5,
    .waiting-user-detail span {
        font-size: 10px;
        margin-top: 0.5rem !important;
    }

    .call-connect .time-count {
        font-size: 10px;
    }

    .notes-btn {
        align-items: center;
    }

    .notes-btn .notes-save-btn {
        font-size: 10px;
        height: 30px;
    }

    .call-connect .call-profile {
        width: 40px;
        height: 40px;
    }

    .call-screen h5 {
        display: none;
    }

    .waiting-user-detail .reload-aleart p {
        margin-left: 30px;
    }
}


/* Birth Chart */

.box-chart {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(4, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    border: 1px solid #FF8A00;
    background-color: #FFE799;
    height: 450px;
    width: 100%;
}

.box1 {
    grid-area: 2 / 2 / 4 / 4;
    border: 1px solid #FF8A00;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.box2 {
    grid-area: 1 / 1 / 2 / 2;
    border: 1px solid #FF8A00;
}

.box3 {
    grid-area: 1 / 2 / 2 / 3;
    border: 1px solid #FF8A00;
}

.box4 {
    grid-area: 1 / 3 / 2 / 4;
    border: 1px solid #FF8A00;
}

.box5 {
    grid-area: 1 / 4 / 2 / 5;
    border: 1px solid #FF8A00;
}

.box6 {
    grid-area: 2 / 4 / 3 / 5;
    border: 1px solid #FF8A00;
}

.box7 {
    grid-area: 3 / 4 / 4 / 5;
    border: 1px solid #FF8A00;
}

.box8 {
    grid-area: 4 / 4 / 5 / 5;
    border: 1px solid #FF8A00;
}

.box9 {
    grid-area: 4 / 3 / 5 / 4;
    border: 1px solid #FF8A00;
}

.box10 {
    grid-area: 4 / 2 / 5 / 3;
    border: 1px solid #FF8A00;
}

.box11 {
    grid-area: 4 / 1 / 5 / 2;
    border: 1px solid #FF8A00;
}

.box12 {
    grid-area: 3 / 1 / 4 / 2;
    border: 1px solid #FF8A00;
}

.box13 {
    grid-area: 2 / 1 / 3 / 2;
    border: 1px solid #FF8A00;
}

.box1 h5,
.box1 h6 {
    line-height: normal;
}

.planetDegree {
    margin-bottom: 10px;
}

.planetName p {
    margin-bottom: 5px;
    line-height: normal;
    font-weight: 600;
    /* color: #000; */
}

.planetName .hide {
    display: none;
}

.planetName .show {
    margin-bottom: 0px;
}

.planetName .show .name {
    max-width: 4ch;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.box-number {
    line-height: normal;
    margin-bottom: 0.5rem;
    color: #000;
}

.birth-planet table {
    width: 100%;
}

.birth-planet table th {
    font-size: 14px;
    padding: 10px;
}

.birth-planet table td {
    font-size: 12px;
    padding: 10px;
}

.chart-red {
    color: #FF0000 !important;
}

.chart-darkBlue {
    color: #030E52 !important;
}

.chart-blue {
    color: #025BFD !important;
}

.chart-green {
    color: #00A611 !important;
}

.chart-black {
    color: #000 !important;
}

@media (min-width:1500px) {
    .box-chart {
        height: 500px;
    }
}

@media (max-width:1300px) {
    .box-chart {
        height: 400px;
    }

    [data-header-position="fixed"] .content-body .planetName p {
        font-size: 10px;
    }

    [data-header-position="fixed"] .content-body .box1 h5,
    [data-header-position="fixed"] .content-body .box1 h6 {
        font-size: 12px;
    }

}

@media (max-width:950px) {
    .waiting-user-detail {
        width: 100% !important;
        margin-bottom: 30px;
        padding: 20px;
    }

    .waiting-user-detail .user-profile {
        width: 100px;
    }

    .call-connect {
        width: 40% !important;
    }


}

@media (max-width:550px) {
    .waiting-user-detail {
        padding: 10px;
    }

    .waiting-user-detail .col-sm-8 {
        width: 65% !important;
    }

    .call-connect {
        width: 90% !important;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .call-connect .col-sm-12 {
        width: 40% !important;
    }

    .call-connect .col-lg-4 {
        width: 40% !important;
    }

    .call-btn {
        width: 30% !important;
    }

    .call-connecting-msg {
        width: 20% !important;
    }

    /* .waiting-details-btn .waiting-details-type{
        margin-top: 60px !important;
    } */

    .box-chart {
        height: 330px;
    }

    .box-number {
        font-size: 10px;
        padding-left: 10px;
    }

    [data-header-position="fixed"] .content-body .planetName p {
        font-size: 8px;
    }

    [data-header-position="fixed"] .content-body .box1 h5,
    [data-header-position="fixed"] .content-body .box1 h6 {
        font-size: 10px;
    }

    .box2,
    .box3,
    .box4,
    .box5,
    .box6,
    .box7,
    .box8,
    .box9,
    .box10,
    .box11,
    .box12,
    .box13 {
        padding: 0 !important;
    }

    .chat-chart-modal .modal-content .modal-body {
        padding: 0 !important;
    }

    .chat-chart-modal .modal-content .modal-body .box-chart {
        height: 380px;
    }

    .chat-chart-modal .modal-content .modal-body .planetName p {
        font-size: 10px;
    }

    .birth-planet table th {
        font-size: 12px;
        padding: 8px;
    }

    .birth-planet table td {
        font-size: 10px;
        padding: 8px;
    }
}

@media (max-width:400px) {
    [data-header-position="fixed"] .content-body .planetName p {
        font-size: 8px;
    }

    [data-header-position="fixed"] .content-body .box1 h5,
    [data-header-position="fixed"] .content-body .box1 h6 {
        font-size: 10px;
    }
}

/* Earmings */
.earnings-filter .react-datepicker__view-calendar-icon input {
    height: 36px;
    width: 100%;
}

.blance-card {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    padding: 30px;
    border-radius: 5px;
}

.blance-card p {
    font-size: 18px;
    margin-bottom: 0px;
    color: rgba(0, 0, 0, 0.4);
    font-weight: 700;
}

.blance-card .avail-balance {
    font-size: 20px;
    color: #000;
    font-weight: 700;
}

.blance-card .avail-balance span {
    color: #258D00;
}

.transaction-table {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    border-radius: 5px;
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.transaction-table table {
    width: 100% !important;
}

.transaction-table th,
.transaction-table tr {
    width: 180px;
    height: 60px;
    text-align: center;
}

.transaction-table .transaction-heading {
    background-color: #FFF7E1;
    line-height: 50px;
    padding: 10px;
}

.transaction-table .transaction-data:nth-child(odd) {
    background-color: #F2F2F2;
}

.payment-success {
    color: #00A611;
}

.payment-failed {
    color: #FF3636;
}

.no-data {
    margin: 60px 0px;
}

.no-data p {
    font-size: 30px;
    font-weight: 700;
    text-align: center;
    color: #B8B8B8;
}

@media (max-width:950px) {

    .transaction-table th,
    .transaction-table tr {
        font-size: 13px;
    }

    .transaction-table .transaction-heading {
        line-height: 25px;
    }
}

@media (max-width:550px) {

    .transaction-table {
        overflow-x: scroll;
    }

    .transaction-table table {
        width: 200% !important;
    }

    .blance-card .avail-balance {
        font-size: 16px;
    }

    .blance-card p {
        font-size: 12px;
    }

    .transaction-table th,
    .transaction-table td {
        font-size: 12px;
    }

    .blance-card {
        padding: 15px;
    }

    .earnings-filter .col-sm-6 {
        width: 50% !important;
    }
}

/* Reviews and Ratings */
.RatingwMain {
    padding-top: 0 !important;
}

.RatingwMain .RatingCard {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    border-radius: 5px;
    padding: 25px;
}

.RatingwMain .RatingCard h4 {
    font-family: sans-serif;
}

.RatingwMain .RatingCard .RatingAverage {
    font-family: sans-serif;
    font-size: 45px;
    font-weight: 700;
    color: #828282;
    margin-bottom: 0px;
}

.RatingwMain .RatingCard .profileStar {
    justify-content: flex-start;
}

.RatingwMain .RatingCard .progress {
    width: 100% !important;
    height: 15px !important;
}

.RatingwMain .RatingCard .progress1 .progress-bar {
    background-color: #028918 !important;
}

.RatingwMain .RatingCard .progress2 .progress-bar {
    background-color: #B08847 !important;
}

.RatingwMain .RatingCard .progress3 .progress-bar {
    background-color: #FFCB70 !important;
}

.RatingwMain .RatingCard .progress4 .progress-bar {
    background-color: #327BFF !important;
}

.RatingwMain .RatingCard .progress5 .progress-bar {
    background-color: #FF0000 !important;
}

.ReviewMain .Reviews-heading {
    background-color: #FFC35A;
}

.ReviewMain .user-reviews {
    height: 500px;
    overflow-y: scroll;
}

.ReviewMain .user-reviews .review-card {
    display: flex;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    padding: 10px;
}

.ReviewMain .astrologer-command p {
    margin-bottom: 5px !important;
}

.ReviewMain .reviewer_profile {
    display: flex;
    gap: 20px;
    align-items: flex-start;
}

.ReviewMain .reviewer_profile .profileImg {
    width: 80px;
    height: 80px;
}

.ReviewMain .reviewer_profile .profileImg img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.profileStar {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.6rem;
    font-size: 14px;
}

.profileStar .colorStar {
    color: #FFB800;
}

.profileStar .NoColorStar {
    color: #C4C4C4;
}

.ReviewMain .reviewer_profile .reviewer_detail {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.ReviewMain .reviewer_detail .review-date p {
    font-size: 10px;
    margin-bottom: 0;
}

.RatingwMain .RatingCard .review_comment p {
    margin-bottom: 0;
}

@media (max-width:950px) {
    .ReviewMain .reviewer_profile {
        align-items: center;
    }

    .ReviewMain .reviewer_profile h5 {
        font-size: 14px;
        margin-bottom: 0;
    }

    .ReviewMain .reviewer_profile .profileStar {
        font-size: 14px;
    }

    .RatingwMain .RatingCard .col-lg-3 {
        width: 25% !important;
    }

    .RatingwMain .RatingCard .col-lg-8 {
        width: 70% !important;
    }

    .ReviewMain .reviewer_profile .profileImg {
        width: 65px;
        height: 65px;
    }
}

@media (max-width:800px) and (min-width:600px) {
    .ReviewMain .reviewer_profile {
        flex-direction: column;
        gap: 5px;
    }

    .ReviewMain .reviewer_profile .reviewer_detail {
        align-items: center;
    }
}

@media (max-width:550px) {
    .ReviewMain .user-reviews .review-card {
        display: flex;
        flex-direction: column;
    }

    .ReviewMain .user-reviews .review-card p {
        font-size: 14px;
    }

    .ReviewMain .reviewer_profile {
        flex-direction: row;
        gap: 20px;
    }

    .ReviewMain .reviewer_profile {
        margin-bottom: 10px;
    }

    .ReviewMain .reviewer_profile .profileImg {
        width: 60px;
        height: 60px;
    }
}


/* Followers */
.followers-list {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
}

.followers-list .follower-card {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    width: 32% !important;
}

.followers-list .follower-card .follower-img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
}

.followers-list .follower-card .follower-img img {
    border-radius: 50%;
}

.followers-list .follower-card .more-icon {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.followers-list .follower-card .more-icon .icon {
    font-size: 24px;
}

.followers-list .follower-card .more-icon .icon:hover {
    color: #030E52;
}

.follower-card p {
    margin-bottom: 0;
}

.followers-list .follower-card .new-user {
    background-color: #028918;
    color: #ffffff;
    border-radius: 25px;
    display: flex;
    justify-content: center;
}

.followers-list .follower-card .new-user p {
    font-size: 12px;
}

.follower-profile {
    background-color: rgba(0, 0, 0, 0.3);
}

.follower-profile .modal-content {
    background-color: #FFF9E6;
}

.follower-detail-img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
}

.follower-detail-img img {
    border-radius: 50%;
}

@media only screen and (max-width: 63.9375rem) {
    .followers-list .follower-card {
        width: 48% !important;
    }
}

@media only screen and (max-width: 47.9375rem) {
    .followers-list .follower-card {
        width: 100% !important;
    }

    .followers-list .follower-card .new-user {
        width: 50px;
    }
}

/* Offers */
.offers-card {
    width: 49% !important;
    background-color: rgba(0, 0, 0, 0.1);
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    padding: 15px;
    border-radius: 5px;
    margin-bottom: 15px;
}

.offers-card .offer-inside-card {
    height: 100%;
    position: relative;
    background-color: #ffffff;
    display: flex;
    justify-content: space-between;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    padding: 15px;
    border-radius: 5px;
}

.offers-card .offer-inside-card p {
    font-size: 14px;
    margin-bottom: 5px;
}

.offers-card .offer-inside-card p span {
    font-weight: 600;
}

.offers-card .offer-inside-card .discount-percentage {
    font-size: 26px;
    font-weight: 600;
}

.offers-card .offer-inside-card .discount-name,
.offers-card .offer-inside-card .validity-date {
    font-size: 16px;
    font-weight: 600;
}

.offers-card .offer-inside-card .description-title {
    font-weight: 600;
    text-decoration: underline;
}

.enable-btn {
    /* position: absolute;
    right: 10px; */
}

.offer-switch {
    width: 70px;
    height: 30px;
}

.offer-switch .switch::before {
    width: 20px;
    height: 20px;
    bottom: 5px;
}

.offer-switch .switch-text {
    font-size: 14px;
    left: 50%;
}

.offer-switch input:checked+.switch {
    background-color: #36AA00;
    border: 1px solid #36AA00;
}

.offer-switch input:checked+.switch::before {
    transform: translateX(32px);
}

.offer-switch input:checked+.offers-card {
    background-color: #FFF0C2 !important;
}

@media only screen and (max-width: 63.9375rem) {
    .offer-list {
        gap: 1.5rem !important;
    }

    .offers-card {
        width: 100% !important;
        margin-bottom: 0;
    }
}

@media only screen and (max-width: 47.9375rem) {
    .offers-card {
        padding: 10px;
    }

    .offers-card .offer-inside-card {
        padding: 10px;
    }

    .offers-card .offer-inside-card .col-sm-5 {
        width: 35% !important;
    }

    .offers-card .offer-inside-card .col-sm-7 {
        width: 65% !important;
    }

    .offers-card .offer-inside-card p {
        font-size: 10px;
    }

    .offers-card .offer-inside-card .discount-name,
    .offers-card .offer-inside-card .validity-date {
        font-size: 12px;
    }

    .offers-card .offer-inside-card .discount-percentage {
        font-size: 22px;
    }

    .offers-card .offer-inside-card .terms li {
        font-size: 10px;
    }

    .offer-switch {
        width: 70px;
        height: 25px;
    }

    .offer-switch .switch::before {
        width: 15px;
        height: 15px;
    }

    .offer-switch .switch-text {
        font-size: 12px;
    }
}

/* Chat Page */
.chat-canvas .offcanvas-header {
    background-color: #030E52;
    text-align: center;
    padding: 5px 20px !important;
    align-items: flex-start;
}

.chat-canvas .offcanvas-header button {
    background-color: #ffffff;
    color: #030E52 !important;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    /* filter: brightness(0) invert(1); */
}

.chat-canvas .offcanvas-body {
    flex-direction: column;
    padding: 10px 0 !important;
    overflow-y: unset;
    position: relative;
    height: 70vh !important;
}

.chat-connect .cus-details {
    background-color: #FFE799;
    border-radius: 5px;
}

.chat-connect h5 {
    font-size: 14px;
    margin-bottom: 0;
    padding: 7px 20px;
}

.chat-connect p {
    background-color: #FFE799;
    font-size: 14px;
    color: #097500;
    margin-bottom: 0;
    padding: 10px 20px;
    border-radius: 5px;
}

.chat-messages {
    height: 100% !important;
    overflow-y: scroll;
    padding: 0 10px;
    padding-bottom: 60px;
}

.chat-messages::-webkit-scrollbar {
    display: none;
}

.chat-person {
    width: 75%;
    font-size: 12px;
    padding: 0px 10px;
}

.chat-message p,
.chat-person p {
    margin-bottom: 0;
}

.type-section {
    background-color: #030E52;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    position: absolute;
    bottom: 0;
}

.type-section .text-input {
    width: 90%;
    position: relative;
    display: flex;
}

.type-section .text-input textarea {
    font-size: 16px;
    width: 100%;
    height: 35px;
    min-height: 35px;
    max-height: 100px;
    border-radius: 25px;
    border: none;
    padding-left: 15px;
    padding-right: 40px;
    resize: none;
}

/* .type-section .text-input textarea::-webkit-scrollbar {
    display: none;
} */

.type-section .text-input button {
    position: absolute;
    right: 5px;
    bottom: 5px;
    border: none;
    outline: none;
    display: flex;
    justify-content: center;
}

.type-section .text-input button .icon {
    color: #030E52;
}

.type-section button {
    background-color: transparent;
    color: #ffffff;
    border: none;
    outline: none;
}

.type-section button .icon {
    font-size: 30px;
}

.chat-message {
    background-color: #FFE799;
    color: #000;
    font-size: 16px;
    padding: 5px 15px;
    /* min-width: 10%; */
    max-width: 70%;
    border-radius: 8px;
    margin-bottom: 15px;
    overflow-wrap: break-word;
}

.chat-image {
    max-width: 300px;
    margin-bottom: 50px;
}

.chat-image img {
    width: 100%;
    height: 100%;
}

.chat-image .image-download {
    background-color: #FFE799;
    color: #030E52;
    display: flex;
    justify-content: space-between;
    padding-top: 5px;
}

.chat-image .image-download p {
    font-size: 14px;
    margin-bottom: 0;
    padding-left: 10px;
    padding-bottom: 5px;
}

.chat-message p {
    margin-bottom: 0;
}

.chat-message span,
.chat-file .file-name span,
.chat-image .image-download span {
    font-family: sans-serif;
    font-size: 10px;
    display: flex;
    justify-content: flex-end;
    margin-right: -8px;
}

.chat-file .file-name span,
.chat-image .image-download span {
    margin-right: 10px;
    margin-bottom: 5px;
}

.chat-file {
    display: flex;
    gap: 10px;
    background-color: #030E52;
    color: #ffffff;
    border-radius: 5px;
    max-width: 85%;
    margin-bottom: 15px;
}

.chat-file .file {
    background-color: #030E52;
    padding: 10px;
    border-right: 1px solid #ffffff;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.chat-file .file .icon {
    font-size: 26px;
    color: #ffffff;
}

.chat-file .file-name p {
    margin-bottom: 0;
    font-size: 14px;
    padding: 10px;
    padding-left: 0;
}

.chat-canvas .user-name {
    text-align: left;
    margin-bottom: 0;
}

.chat-canvas .balance-time {
    font-size: 14px;
    margin-bottom: 0;
    color: #ffffff;
}

.chat-canvas .balance-time .time-count {
    color: #CA0505;
}

.chat-canvas .typing-status {
    font-size: 14px;
    font-weight: 600;
    text-align: left;
    color: #ffffff;
    margin-bottom: 3px;
}

.chat-canvas .typing-status.hide {
    opacity: 0;
}

.chat-history-body {
    position: relative;
}

.chat-history-body .chat-history-message {
    position: absolute;
    top: 0;
}

.notconnect-alert .modal-body {
    padding: 10px;
    text-align: center;
}

.notconnect-alert .modal-body p {
    font-size: 16px;
    color: #000;
}

.notconnect-alert .modal-body button {
    background-color: #030E52;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 5px;
}

@media only screen and (max-width: 67.9375rem) {
    .chat-canvas {
        top: 0 !important;
        right: 0 !important;
    }

    .type-section {
        width: 100%;
    }

    .type-section .text-input {
        width: 80%;
    }

    .chart-img {
        width: 25px;
        height: 25px;
    }

    .chat-file .file-name p {
        font-size: 12px;
    }

    .chat-message span,
    .chat-file .file-name span,
    .chat-image .image-download span {
        font-size: 8px;
    }
}

@media only screen and (max-width: 47.9375rem) {
    .chat-canvas .modal-content {
        width: 80% !important;
    }

    .chat-canvas .user-name {
        font-size: 13px;
    }

    .chat-canvas .balance-time {
        font-size: 11px;
    }

    .chat-canvas .typing-status {
        font-size: 10px;
    }

    .chat-canvas .offcanvas-header button {
        font-size: 10px;
        padding: 2px 10px;
    }

    .chat-connect p,
    .chat-connect h5,
    .chat-message {
        font-size: 12px;
    }

    .chat-connect h5 {
        padding: 5px 20px;
    }

    .chat-history-body .chat-history-message {
        position: unset;
        height: 250px !important;
        padding-top: 0;
    }
}


/* Support Chat */
.support-chat {
    height: 65vh;
    /* box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px; */
}

.support-chat .helf,
.support-chat .chat-screen {
    padding: 0;
}

.support-chat .title {
    background-color: #004EDD;
    text-align: center;
    color: #ffffff;
    padding: 5px 0;
    position: relative;
}

.support-chat .title .icon {
    position: absolute;
    left: 20px;
    top: 8px;
    font-size: 24px;
}

.support-chat .chat-screen .chat {
    position: relative;
    background-color: #F0F0F0;
    height: 92%;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.support-chat .chat-screen .chat .chat-view-screen {
    height: 65vh !important;
    padding-bottom: 60px !important;
    overflow-y: scroll;
}

.support-chat .chat-screen .chat .chat-view-screen::-webkit-scrollbar {
    display: none;
}

.support-chat .chat-screen .chat .type-section {
    position: absolute;
    bottom: 0;
    background-color: #004EDD;
    justify-content: center;
    gap: 10px;
}

.support-chat .chat-screen .chat .type-section .text-input {
    width: 85%;
}

.support-chat .chat-screen .chat .type-section .text-input textarea {
    height: 30px;
}

.support-chat .chat-screen .chat .type-section button .icon {
    font-size: 26px;
}

.support-chat .helf .helf-inside {
    position: relative;
    height: 92%;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.support-chat .helf .nav-tabs .nav-item {
    width: 50% !important;
    background-color: #FFE799;
}

.support-chat .helf .nav-tabs .nav-link {
    width: 100%;
    font-size: 14px;
    padding: 5px;
    color: #030E52;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.support-chat .helf .nav-tabs .nav-link.active {
    font-weight: 700;
    box-shadow: none;
}

.support-chat .helf .tab-content .collapse:not(.show) {
    display: none !important;
}

.support-chat .helf .tab-content .accordion .accordion-item {
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    transition: none;
    margin-bottom: 0 !important;
}

.support-chat .helf .tab-content .accordion .accordion-item .accordion-button {
    box-shadow: none !important;
    padding: 15px 25px !important;
    font-size: 14px;
}

.support-chat .helf .tab-content .accordion .accordion-item .accordion-button:not(.collapsed) {
    background-color: #FFE799 !important;
    box-shadow: none !important;
}

.support-chat .helf .tab-content .ticket {
    display: flex;
    justify-content: space-between;
    padding: 10px 25px !important;
    font-size: 14px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.support-chat .helf .tab-content .ticket p {
    margin-bottom: 0;
}

.support-chat .helf .tab-content .ticket .date {
    font-size: 11px;
}

.support-chat .helf .tab-content .ticket .status {
    font-weight: 700;
}

.support-chat .helf .tab-content .ticket .status.open {
    color: #00A611;
}

.support-chat .helf .tab-content .ticket .status.pending {
    color: #FFAE1F;
}

.support-chat .helf .tab-content .ticket .status.close {
    color: #030E52;
}

.support-chat .helf .helf-inside .create-ticket {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 55px;
    background-color: #030E52;
    color: #ffffff;
}

.issue-modal .create-issue input,
.issue-modal .create-issue select,
.issue-modal .create-issue textarea {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #000;
    outline: none;
    padding: 5px 10px;
}

.issue-modal .create-issue input,
.issue-modal .create-issue select {
    height: 35px;
}

@media (max-width:950px) {
    .support-chat .chat-screen .chat .type-section {
        width: 100%;
    }

}


/* Order Alert */
.alert-card {
    display: flex;
    align-items: center;
    gap: 10px;
    background-color: #ffffff;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    height: 150px;
    width: 500px;
    position: fixed;
    right: 30%;
    bottom: 40%;
    border-radius: 5px;
    padding: 15px;
    padding-top: 5px;
}

.alert-card .alert-icon-circle {
    background-color: #030E52;
    height: 100px;
    width: 100px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.alert-card .alert-icon-circle .icon {
    font-size: 60px;
    color: #ffffff;
}

.alert-card .alert-message .icon {
    font-size: 30px;
    position: absolute;
    top: 10px;
    right: 10px;
    /* margin-bottom: 5px; */
    cursor: pointer;
}

.alert-card .alert-message p {
    font-size: 24px;
    font-weight: 600;
    color: #030E52;
    /* padding-top: 5px; */
    margin-bottom: 0;
}

.alert-card .alert-message button {
    background-color: #FFAE1F;
    color: #ffffff;
    border: none;
    border-radius: 25px;
    padding: 8px 30px;
    font-size: 16px;
    cursor: pointer;
}

.alert-card .alert-message button:hover {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

@media only screen and (max-width: 67.9375rem) {
    .alert-card {
        height: 120px;
    }

    .alert-card .alert-icon-circle {
        height: 80px;
        width: 80px;
    }

    .alert-card .alert-icon-circle .icon {
        font-size: 50px;
    }

    .alert-card .alert-message p {
        font-size: 22px;
    }

    .alert-card .alert-message button {
        padding: 5px 30px;
    }
}

@media only screen and (max-width: 47.9375rem) {
    .alert-card {
        width: 90%;
        height: 100px;
        right: 8%;
    }

    .alert-card .alert-message p {
        font-size: 16px;
    }

    .alert-card .alert-icon-circle {
        height: 60px;
        width: 60px;
    }

    .alert-card .alert-icon-circle .icon {
        font-size: 40px;
    }

    .alert-card .alert-message button {
        padding: 5px 20px;
        font-size: 14px;
    }

    .alert-card .alert-message .icon {
        font-size: 24px;
    }
}

/* Notification */
.notification-list {
    position: relative;
    padding: 20px;
    border-radius: 10px;
    background-color: #F2F2F2;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    cursor: pointer;
}

.notification-list.not-seen {
    background-color: #FFF7E1;
    font-weight: 700;
    /* border: 1px solid #000; */
}

.notification-list.not-seen h6 {
    font-weight: 700;
}

.notification-list p {
    font-size: 14px;
    margin-bottom: 0px;
}

.notification-list .notify-date {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 12px;
}

/* Custom Pagination */
.custom-pagination {
    display: flex;
    justify-content: center;
    gap: 10px;
    padding: 20px 0;
}

.custom-pagination li a {
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid gray;
    border-radius: 5px;
    cursor: pointer;
}

.custom-pagination li.selected a,
.custom-pagination li a:hover {
    background-color: #030E52;
    border: 1px solid #030E52;
    color: #ffffff;
}

.custom-pagination .break a {
    border: none;
    font-size: 16px;
}

@media only screen and (max-width: 47.9375rem) {
    .custom-pagination li a {
        width: 25px;
        height: 25px;
        font-size: 12px;
    }
}